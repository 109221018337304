import React from 'react'

import PropTypes from 'prop-types'

import './member-details.css'

const MemberDetails = (props) => {
  return (
    <div className="member-details-container">
      <img
        alt={props.imageAlt}
        src={props.imageSrc}
        className="member-details-image"
      />
      <h1 className="member-details-text TextXL">{props.heading1}</h1>
      <h1 className="member-details-text1 TextSM">{props.heading11}</h1>
    </div>
  )
}

MemberDetails.defaultProps = {
  imageAlt1: 'image',
  imageSrc1:
    'https://images.unsplash.com/photo-1589149098258-3e9102cd63d3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE1fHxpbnRlbGlnZW5jaWElMjBhcnRpZmljaWFsfGVufDB8fHx8MTcxMjI2MTAyOXww&ixlib=rb-4.0.3&q=80&w=1080',
  heading11: 'CEO - SRE',
  heading1: 'Rodrigo Moreira',
  imageAlt: 'image',
  imageSrc:
    'https://demos.creative-tim.com/notus-pro-react/static/media/team-1-800x800.fa5a7ac2.jpg',
}

MemberDetails.propTypes = {
  imageAlt1: PropTypes.string,
  imageSrc1: PropTypes.string,
  heading11: PropTypes.string,
  heading1: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc: PropTypes.string,
}

export default MemberDetails
