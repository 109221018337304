import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import MemberDetails from '../components/member-details'
import SecondaryButton from '../components/secondary-button'
import Footer from '../components/footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Simbio WEBSITE</title>
        <meta property="og:title" content="Simbio WEBSITE" />
      </Helmet>
      <div className="home-hero">
        <div className="home-bg"></div>
        <a
          href="https://www.linkedin.com/company/simbio-it/about/"
          target="_blank"
          rel="noreferrer noopener"
          className="home-link"
        >
          <Header className="home-component"></Header>
        </a>
        <div className="home-container01">
          <div className="home-container02">
            <h1 className="home-text">Onde Nuvem e IA se Encontram</h1>
            <span className="home-text01">
              A SimbioIT lidera com inovação em IA para automação, eficiência e
              insights preditivos em gestão de nuvem, sendo um parceiro
              estratégico para superação de desafios e sucesso sustentável.
            </span>
          </div>
          <img alt="image" src="/gray-vector.svg" className="home-image" />
          <img alt="image" src="/white-vector.svg" className="home-image1" />
        </div>
      </div>
      <div className="home-section1">
        <div className="home-container03">
          <div className="home-container04">
            <h3 className="home-text02">
              Integrando Nuvens, Potencializando Transformações
            </h3>
            <span className="home-text03">
              Inovamos a gestão de nuvem e soluções de tecnologia, dedicada a
              moldar o futuro da operação digital através de práticas avançadas
              de SRE, DevOps, DevSecOps, monitoramento e FinOps. Nossa missão é
              capacitar organizações de todos os tamanhos a maximizar a
              eficiência, segurança e inovação de suas infraestruturas de nuvem,
              garantindo ao mesmo tempo a otimização de custos e a conformidade
            </span>
          </div>
          <div className="home-cards-container">
            <div className="home-card1">
              <div className="home-container05"></div>
              <h6 className="home-text04 TextXL">DEVOPS</h6>
              <span className="home-text05">
                Oferecemos um serviço de DevOps que integra desenvolvimento e
                operações para otimizar a entrega de software. Através de
                automação, integração e entrega contínuas, nosso serviço visa
                acelerar lançamentos, melhorar a colaboração e garantir a
                confiabilidade do software, elevando a eficiência e a inovação
                do seu negócio.
              </span>
            </div>
            <div className="home-card2">
              <div className="home-container06"></div>
              <h6 className="home-text06 TextXL">SRE </h6>
              <span className="home-text07">
                Nosso foco está na automação, na precisão das medições de
                desempenho e na definição de objetivos de nível de serviço
                (SLOs), garantindo sistemas altamente disponíveis e confiáveis.
                Com nosso serviço de SRE, comprometemo-nos a elevar
                continuamente a qualidade e a estabilidade de suas operações
                digitais, assegurando um ambiente tecnológico robusto para o seu
                negócio.
              </span>
            </div>
            <div className="home-card3">
              <div className="home-container07"></div>
              <h6 className="home-text08 TextXL">FINOPS</h6>
              <span className="home-text09">
                Nossa oferta em FinOps concentra-se em otimizar os custos de
                nuvem e o valor do investimento em tecnologia. Utilizamos
                práticas de gestão financeira para equilibrar despesas,
                eficiência e agilidade, assegurando que cada dólar investido em
                nuvem contribua diretamente para o crescimento e a inovação do
                seu negócio.
              </span>
            </div>
          </div>
          <div className="home-work-with-us">
            <div className="home-container08">
              <h3 className="home-text10">
                <span>Gestão de Nuvem</span>
                <br></br>
                <span>Maximizando Desempenho e Segurança</span>
              </h3>
              <span className="home-text14">
                Nosso serviço de Gestão de Nuvem oferece a expertise necessária
                para maximizar o desempenho, segurança e eficiência da sua
                infraestrutura em nuvem. Focamos em uma gestão estratégica,
                garantindo que sua transição e operação na nuvem sejam suaves,
                seguras e otimizadas para alinhar-se com os objetivos de
                negócio, promovendo agilidade, escalabilidade e inovação
                contínua.
              </span>
              <span className="home-text15">Fale com um especialista</span>
            </div>
            <div className="home-container09">
              <div className="home-container10">
                <img alt="image" src="/blue_wave.svg" className="home-image2" />
              </div>
              <div className="home-container11">
                <h4 className="home-text16 Healine">
                  <span className="home-text17">Suporte 24h</span>
                  <br></br>
                </h4>
                <span className="home-text19">
                  Assistência especializada para otimizar, segurar e gerenciar
                  infraestruturas de nuvem, garantindo eficiência operacional e
                  conformidade.
                </span>
              </div>
            </div>
          </div>
        </div>
        <img alt="image" src="/white-vector.svg" className="home-image3" />
      </div>
      <div className="home-section2">
        <div className="home-container12">
          <h3 className="home-text20">Nossa Equipe: Inovação e Excelência</h3>
          <span className="home-text21">
            Contamos com uma equipe de especialistas apaixonados, dedicados a
            transformar desafios em soluções inovadoras de nuvem. Nossa força
            está na diversidade, expertise e compromisso com a excelência,
            impulsionando o sucesso dos nossos clientes.
          </span>
        </div>
        <div className="home-team">
          <div className="home-container13">
            <div className="home-container14">
              <MemberDetails imageSrc="/0-200h.jpg"></MemberDetails>
            </div>
            <div className="home-container15">
              <MemberDetails
                heading1="Bruno Arruda"
                heading11="CEO - Devops"
                imageSrc="/soldado-200h.jpg"
              ></MemberDetails>
            </div>
            <div className="home-container16">
              <MemberDetails
                heading1="Francis Rizzo"
                heading11="CEO - Finops"
                imageSrc="/francis-200h.jpeg"
              ></MemberDetails>
            </div>
            <div className="home-container17">
              <MemberDetails
                heading1="CREW IA"
                heading11="IA BOT"
                imageSrc="/download-200h.png"
              ></MemberDetails>
            </div>
          </div>
        </div>
      </div>
      <div className="home-section3">
        <div className="home-container18">
          <div className="home-contact">
            <h2 className="home-text22">Entre em Contato</h2>
            <span className="home-text23">
              Vamos conversar sobre o futuro da sua infraestrutura em nuvem.
              Entre em contato conosco hoje mesmo e descubra como podemos
              ajudá-lo a alcançar novas alturas de inovação e eficiência.
            </span>
            <div className="home-form">
              <h1 className="home-text24">Fale conosco ! </h1>
              <span className="home-text25 TextXL">
                <span>
                  Complete o
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>Formulário</span>
                <span> e entraremos em contato em 24h</span>
              </span>
              <span className="home-text29 TextXS">FULL NAME</span>
              <input
                type="text"
                placeholder="Full Name"
                className="home-textinput TextSM input"
              />
              <span className="home-text30 TextXS">EMAIL</span>
              <input
                type="text"
                placeholder="Email"
                className="home-textinput1 TextSM input"
              />
              <div className="home-container19">
                <SecondaryButton
                  button="Enviar"
                  rootClassName="secondary-button-root-class-name"
                ></SecondaryButton>
              </div>
            </div>
          </div>
        </div>
        <img alt="image" src="/gray-vector.svg" className="home-image4" />
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Home
